// Import all of Bootstrap's CSS

@import "~bootstrap/scss/bootstrap";

/* =========== Custom Styles =========== */
@import "icon-list";


/* =========== Wrapper / Body =========== */
body, html {
  height: 100%;
}

body {
  background: #f2f5fc url('../img/sleepology-bg.svg') no-repeat center 100px;
  background-size: cover;
  height: 100%;
}


/* =========== Header =========== */
#header-wrapper {
  #logo {
    width: 184px;
  }

  #nav {
    padding: 36px 0;

    ul li {
      a.nav-link {
        color: #4857ee;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        padding: 13px 27px 14px 28px;
        text-transform: lowercase;

        &.active {
          background: #4857ee;
          border-radius: 20px 0;
          color: #fff;

          &:hover {
            background: rgba(#4857ee, .75);
          }
        }
      }
    }
  }
}


/* =========== Banner =========== */
#banner-wrapper #banner {
  height: 100vh;
  margin-bottom: 108px;
  padding-top: 150px;

  input,
  button {
    font-family: 'Questrial', sans-serif;
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  .page {
    padding-top: 0.8em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &:before {
      content: "";
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: #f00;
      z-index: -1;
    }

    &:after {
      content: "";
      position: relative;
      display: block;
    }
  }

  .content {
    position: relative;
    width: 100%;
    max-width: 970px;
    min-width: 300px;
  }

  .content-large-text {
    color: #222;
    font-family: 'Questrial', sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 73px;
    padding-bottom: 20px;
    text-align: left;
    //text-transform: lowercase;

    span {
      color: #4857ee;
    }
  }

  .content-btn {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-transform: lowercase;
  }

  .content-time-picker {
    margin-bottom: 1em;
    max-width: 36em;
  }

  .content-section {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) translateZ(0);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    width: 100%;
  }

  .content-section-hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(2em);
  }

  .content-section-prevent {
    pointer-events: none;
  }

  .content-section:not(:first-child) {
    position: absolute;
    top: 0;
  }

  .time-view {
    line-height: 1.4;

    .time-view-title {
      text-transform: lowercase;
      color: #222;
      padding-bottom: 20px;
      font-family: 'Questrial', sans-serif;
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 73px;
    }

    .time-view-text {
      color: #000;
      margin-bottom: .5em;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
    }

    .time-view-text-mb {
      margin-bottom: 1.75em;
    }

    .time-view-text-hidden {
      display: none;
    }

    .time-view-list {
      margin-bottom: .5em;
    }
  }

  @media screen and (min-width: 350px) {
    .time-view-title {
      font-size: 1.5em;
    }

    .time-view-text {
      font-size: 1em;
    }

    .time-view-text-mb {
      margin-bottom: 1em;
    }
  }

  @media screen and (min-width: 970px) {
    .time-view-title {
      font-size: 1.75em;
    }

    .time-view-text {
      font-size: 1.5em;
    }

    .time-view-list {
      margin-bottom: .5em;
    }
  }

  .time-list {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    max-width: 31em;
    margin-left: auto;
    margin-right: auto;
  }

  .time-list-text {
    white-space: nowrap;
    font-weight: 600;
    line-height: 0.8;
  }

  .time-list-item {
    border-radius: 20px 0;
    background-color: #4857ee;
    width: calc(50% - .285em);
    font-size: 1em;
    box-shadow: 0 2px 8px -4px rgba(5, 12, 45, 0.2);
    height: 2.42em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .57em;

    &:after {
      content: none;
      color: #efefef;
      font-size: .5em;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.04em;
      line-height: .9;
    }
  }

  .time-list-item-suggested {
    justify-content: space-between;
    flex-direction: column;
    height: auto;
    padding: .65em;
    font-size: 1.125em;

    .time-list-text {
      margin-bottom: .35em;
    }

    &:after {
      content: "suggested";
    }
  }

  @media screen and (min-width: 350px) {
    .time-list-item {
      width: calc(25% - .55em);
    }

    .time-list-item:after {
      margin-top: 0.15em;
    }

    .time-list-item-suggested {
      flex-direction: row;
      height: 2.15em;
      padding: 0 .5em;
      width: calc(50% - .325em);
    }

    .time-list-item-suggested .time-list-text {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 970px) {
    .time-list-item {
      font-size: 1.25em;
      height: 2.4em;
      margin-bottom: .8em;
      width: calc(25% - .75em);
    }

    .time-list-item-suggested {
      width: calc(50% - .4em);
      font-size: 1.5em;
      height: 2em;
    }
  }

  .btn-primary,
  .button {
    border: 1px solid #4857ee;
    background: #4857ee;
    border-radius: 20px 0;
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #fff;
    padding: 19px 38px;
    text-transform: lowercase;
    transition: background-color 0.3s;

    &:hover {
      background: rgba(#4857ee, .75);
    }
  }

  .button-mb {
    margin-bottom: 2em;
  }

  @media screen and (min-width: 970px) {
    .button-mb {
      margin-bottom: 1.75em;
    }
  }

  .select-wrap {
    position: relative;
    height: 100%;
    text-align: center;
    overflow: hidden;
    font-size: 20px;
    color: #ddd;

    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      display: block;
      content: '';
      width: 100%;
      height: 30%;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    .select-options {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0;
      margin: 0 auto;
      display: block;
      transform-style: preserve-3d;
      -ms-transform-style: none;
      transform: translateZ(-150px) rotateX(0deg);
      -ms-transform: none;
      -webkit-font-smoothing: subpixel-antialiased;
      color: #545973;
      will-change: transform;
      list-style: none;

      .select-option {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        will-change: visibility;
        -webkit-font-smoothing: subpixel-antialiased;

        &:nth-child(1) {
          transform: rotateX(0deg) translateZ(150px);
        }

        &:nth-child(2) {
          transform: rotateX(-18deg) translateZ(150px);
        }

        &:nth-child(3) {
          transform: rotateX(-36deg) translateZ(150px);
        }

        &:nth-child(4) {
          transform: rotateX(-54deg) translateZ(150px);
        }

        &:nth-child(5) {
          transform: rotateX(-72deg) translateZ(150px);
        }

        &:nth-child(6) {
          transform: rotateX(-90deg) translateZ(150px);
        }

        &:nth-child(7) {
          transform: rotateX(-108deg) translateZ(150px);
        }

        &:nth-child(8) {
          transform: rotateX(-126deg) translateZ(150px);
        }

        &:nth-child(9) {
          transform: rotateX(-144deg) translateZ(150px);
        }

        &:nth-child(10) {
          transform: rotateX(-162deg) translateZ(150px);
        }

        &:nth-child(11) {
          transform: rotateX(-180deg) translateZ(150px);
        }

        &:nth-child(12) {
          transform: rotateX(-198deg) translateZ(150px);
        }

        &:nth-child(13) {
          transform: rotateX(-216deg) translateZ(150px);
        }

        &:nth-child(14) {
          transform: rotateX(-234deg) translateZ(150px);
        }

        &:nth-child(15) {
          transform: rotateX(-252deg) translateZ(150px);
        }

        &:nth-child(16) {
          transform: rotateX(-270deg) translateZ(150px);
        }

        &:nth-child(17) {
          transform: rotateX(-288deg) translateZ(150px);
        }

        &:nth-child(18) {
          transform: rotateX(-306deg) translateZ(150px);
        }

        &:nth-child(19) {
          transform: rotateX(-324deg) translateZ(150px);
        }

        &:nth-child(20) {
          transform: rotateX(-342deg) translateZ(150px);
        }

        &:nth-child(21) {
          transform: rotateX(-360deg) translateZ(150px);
        }

        &:nth-child(22) {
          transform: rotateX(-378deg) translateZ(150px);
        }

        &:nth-child(23) {
          transform: rotateX(-396deg) translateZ(150px);
        }

        &:nth-child(24) {
          transform: rotateX(-414deg) translateZ(150px);
        }

        &:nth-child(25) {
          transform: rotateX(-432deg) translateZ(150px);
        }

        &:nth-child(26) {
          transform: rotateX(-450deg) translateZ(150px);
        }

        &:nth-child(27) {
          transform: rotateX(-468deg) translateZ(150px);
        }

        &:nth-child(28) {
          transform: rotateX(-486deg) translateZ(150px);
        }

        &:nth-child(29) {
          transform: rotateX(-504deg) translateZ(150px);
        }

        &:nth-child(30) {
          transform: rotateX(-522deg) translateZ(150px);
        }

        &:nth-child(31) {
          transform: rotateX(-540deg) translateZ(150px);
        }

        &:nth-child(32) {
          transform: rotateX(-558deg) translateZ(150px);
        }

        &:nth-child(33) {
          transform: rotateX(-576deg) translateZ(150px);
        }

        &:nth-child(34) {
          transform: rotateX(-594deg) translateZ(150px);
        }

        &:nth-child(35) {
          transform: rotateX(-612deg) translateZ(150px);
        }

        &:nth-child(36) {
          transform: rotateX(-630deg) translateZ(150px);
        }

        &:nth-child(37) {
          transform: rotateX(-648deg) translateZ(150px);
        }

        &:nth-child(38) {
          transform: rotateX(-666deg) translateZ(150px);
        }

        &:nth-child(39) {
          transform: rotateX(-684deg) translateZ(150px);
        }

        &:nth-child(40) {
          transform: rotateX(-702deg) translateZ(150px);
        }

        &:nth-child(41) {
          transform: rotateX(-720deg) translateZ(150px);
        }

        &:nth-child(42) {
          transform: rotateX(-738deg) translateZ(150px);
        }

        &:nth-child(43) {
          transform: rotateX(-756deg) translateZ(150px);
        }

        &:nth-child(44) {
          transform: rotateX(-774deg) translateZ(150px);
        }

        &:nth-child(45) {
          transform: rotateX(-792deg) translateZ(150px);
        }

        &:nth-child(46) {
          transform: rotateX(-810deg) translateZ(150px);
        }

        &:nth-child(47) {
          transform: rotateX(-828deg) translateZ(150px);
        }

        &:nth-child(48) {
          transform: rotateX(-846deg) translateZ(150px);
        }

        &:nth-child(49) {
          transform: rotateX(-864deg) translateZ(150px);
        }

        &:nth-child(50) {
          transform: rotateX(-882deg) translateZ(150px);
        }

        &:nth-child(51) {
          transform: rotateX(-900deg) translateZ(150px);
        }

        &:nth-child(52) {
          transform: rotateX(-918deg) translateZ(150px);
        }

        &:nth-child(53) {
          transform: rotateX(-936deg) translateZ(150px);
        }

        &:nth-child(54) {
          transform: rotateX(-954deg) translateZ(150px);
        }

        &:nth-child(55) {
          transform: rotateX(-972deg) translateZ(150px);
        }

        &:nth-child(56) {
          transform: rotateX(-990deg) translateZ(150px);
        }

        &:nth-child(57) {
          transform: rotateX(-1008deg) translateZ(150px);
        }

        &:nth-child(58) {
          transform: rotateX(-1026deg) translateZ(150px);
        }

        &:nth-child(59) {
          transform: rotateX(-1044deg) translateZ(150px);
        }

        &:nth-child(60) {
          transform: rotateX(-1062deg) translateZ(150px);
        }
      }
    }
  }

  .highlight {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    overflow: hidden;
    box-shadow: 0 5px 8px -3px rgba(3, 6, 23, 0.3);
  }

  .highlight-list {
    position: absolute;
    width: 100%;
    list-style: none;
  }

  /* time */
  .time-picker {
    position: relative;
    perspective: 2000px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 10em;
    border-radius: 20px 0;
    background-color: rgba(195, 211, 250, 1);
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 0;
      margin-top: -1em;
      height: 2em;
      width: 100%;
      border-top: 1px solid #4857ee;
      border-bottom: 1px solid #4857ee;
      pointer-events: none;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      box-shadow: inset 0 5px 8px -3px rgba(210, 222, 251, 0.3);
    }

    > div {
      flex: 1;
    }

    .select-wrap {
      font-size: 1.125em;
      cursor: ns-resize;
    }

    .highlight {
      font-size: 1.375em;
      font-weight: 600;
      background-color: #4857ee;
    }
  }
  
  .time-picker-inner-pl {
    .highlight-item {
      padding-left: 2.55em;
    }

    .select-option {
      padding-left: 3.5em;
    }
  }

  .time-picker-inner-pr {
    .highlight-item {
      padding-right: 2.55em;
    }

    .select-option {
      padding-right: 3.5em;
    }
  }
}

/* =========== Why Sleepology =========== */
#why-sleepology-wrapper #why-sleepology {
  h1 {
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    text-transform: lowercase;
    color: #222;
    padding-bottom: 20px;
  }

  p {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }

  .reason {
    padding: 22px 0;

    h6 {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
    }
  }
}


/* =========== How Sleepology Works =========== */
#how-sleepology-works-wrapper #how-sleepology-works {
  padding-top: 250px;
  padding-bottom: 250px;

  h1 {
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    text-transform: lowercase;
    color: #222;
    padding-bottom: 20px;
  }

  #steps .step {
    margin: 40px 0;

    .step-img {
      margin-right: 20px;
    }

    .step-detail {
      max-width: 400px;

      h6, p {
        color: #222;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
      }

      h6 {
        font-weight: 700;
      }

      p {
        font-weight: 300;
      }
    }
  }
}


/* =========== Sleep Study =========== */
#sleep-study-wrapper #sleep-study {
  padding-top: 135px;
  padding-bottom: 330px;

  .key-points-header {
    padding-bottom: 30px;

    h1 {
      font-family: 'Questrial', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 73px;
      text-transform: lowercase;
      color: #222;
      padding-bottom: 20px;
    }

    p {
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .key-points-list {
    list-style-image: url('../img/star.svg');
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 40px;
    padding-left: 18px;
    margin-bottom: 40px;

    span {
      padding-left: 5px;
    }
  }

  .btn.btn-primary {
    background: #4857ee;
    border-radius: 20px 0;
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #fff;
    padding: 19px 38px;

    &:hover {
      background: rgba(#4857ee, .75);
    }
  }

}


/* =========== Sleep Study =========== */
#get-started-wrapper #get-started {
  padding-top: 80px;

  h1 {
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    text-transform: lowercase;
    color: #222;
    padding-bottom: 20px;
  }

  p {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 44px;
  }

  .btn.btn-primary {
    background: #4857ee;
    border-radius: 20px 0;
    font-family: 'Questrial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #fff;
    padding: 19px 38px;

    &:hover {
      background: rgba(#4857ee, .75);
    }
  }

  img {
    display: block;
    margin: 96px auto;
  }
}

#footer-wrapper #footer {
  height: 100px;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  #banner-wrapper #banner {
    margin-bottom: 100px;
    height: 100%;
  }

  #how-sleepology-works-wrapper #how-sleepology-works {
    padding-top: 100px;
    padding-bottom: 0;
  }

  #sleep-study-wrapper #sleep-study {
    padding-bottom: 50px;
  }

  #banner-wrapper #banner .content-large-text,
  #why-sleepology-wrapper #why-sleepology h1,
  #sleep-study-wrapper #sleep-study .key-points-header h1,
  #how-sleepology-works-wrapper #how-sleepology-works h1,
  #get-started-wrapper #get-started h1 {
    font-size: 40px;
    line-height: initial;
  }
}
